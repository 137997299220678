<template>
  <!-- 重构 - 结算管理 - 待结算列表 - 收款结算单 -->
  <div>
    <div class="operationControl searchboxfl">
      <div class="searchbox">
        <div title="班级编码" class="searchboxItem ci-full">
          <span class="itemLabel">班级编码:</span>
          <el-input
            v-model="retrievalDatas.projectCode"
            type="text"
            size="small"
            placeholder="请输入班级编码"
            clearable
          />
        </div>
        <div title="班级名称" class="searchboxItem ci-full">
          <span class="itemLabel">班级名称:</span>
          <el-input
            v-model="retrievalDatas.projectName"
            type="text"
            size="small"
            placeholder="请输入班级名称"
            clearable
          />
        </div>
        <div title="所属机构" class="searchboxItem ci-full">
          <span class="itemLabel">所属机构:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.compId"
            remote
            :remote-method="getCompanyList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in companyList"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </div>

        <div title="开班开始时间" class="searchboxItem">
          <span class="itemLabel" style="min-width: 8rem">开班开始时间:</span>
          <el-date-picker
            clearable
            size="small"
            v-model="retrievalDatas.startTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
      </div>
      <div class="searchbox" style="margin-top: 0.5rem">
        <div title="行政区划" class="searchboxItem">
          <span class="itemLabel">行政区划:</span>
          <el-cascader
            clearable
            filterable
            v-model="retrievalDatas.areaId"
            :options="areatreeList"
            :props="propsarea"
            size="small"
          ></el-cascader>
        </div>

        <div title="培训类型" class="searchboxItem">
          <span class="itemLabel">培训类型:</span>
          <tree
            ref="tree"
            @eventBtn="childBack"
            :ruleForm="ruleForm"
            size="small"
            @clearParams="clearParams"
            typeStu
          />
        </div>
        <div title="班级状态" class="searchboxItem">
          <span class="itemLabel">班级状态:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.projectState"
            placeholder="请选择班级状态"
            clearable
            ><el-option
              v-for="item in ClassstatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>

        <div title="开班结束时间" class="searchboxItem">
          <span class="itemLabel" style="min-width: 8rem">开班结束时间:</span>
          <el-date-picker
            clearable
            size="small"
            v-model="retrievalDatas.endTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
      </div>
      <div class="searchbox" style="margin-top: 0.5rem">
        <div title="签订机构" class="searchboxItem ci-full">
          <span class="itemLabel">签订机构:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.signCompId"
            remote
            :remote-method="getSignagencyList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in SignagencyList"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </div>
        <div class="df" style="padding-left: 10px">
          <el-button
            type="primary"
            class="bgc-bv"
            round
            @click="getData((pageNum = 1), 'search')"
            >查询</el-button
          >
          <el-button
            type="primary"
            class="bgc-bv"
            round
            @click="generateStatement"
            >生成结算单</el-button
          >
          <!-- <el-button
            type="primary"
            class="bgc-bv"
            round
            @click="generateOldStatement"
            >生成旧结算单</el-button
          > -->
          <el-button
            type="primary"
            class="bgc-bv"
            round
            @click="generateVoidStatement"
            >生成作废班级结算单</el-button
          >
          <el-button type="primary" class="bgc-bv" round @click="getExport()"
            >导出</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          row-key="projectId"
          style="width: 100%"
          @selection-change="checkListData"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            type="selection"
            width="50px"
            align="center"
            fixed
            :reserve-selection="true"
            :selectable="selectable"
          ></el-table-column>
          <el-table-column
            label="序号"
            align="left"
            type="index"
            :index="indexMethod"
            fixed
            width="60px"
          />
          <el-table-column
            label="班级编号"
            align="left"
            show-overflow-tooltip
            prop="projectCode"
            width="260"
          />
          <el-table-column
            label="班级名称"
            align="left"
            show-overflow-tooltip
            prop="projectName"
            width="300"
          />
          <el-table-column
            label="所属机构"
            align="left"
            show-overflow-tooltip
            prop="compName"
            width="220"
          />
          <!-- <el-table-column
            label="签订机构"
            align="left"
            show-overflow-tooltip
            prop="signCompName"
            width="220"
          /> -->
          <el-table-column
            label="行政区划"
            align="left"
            show-overflow-tooltip
            prop="areaNamePath"
            width="260"
          />

          <el-table-column
            label="开班开始时间"
            align="left"
            prop="createTime"
            width="150"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.startDate | momentDate }}
            </template>
          </el-table-column>

          <el-table-column
            label="开班结束时间"
            align="left"
            prop="createTime"
            width="150"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.endDate | momentDate }}
            </template>
          </el-table-column>

          <el-table-column
            label="培训类型"
            align="left"
            show-overflow-tooltip
            prop="trainTypeNamePath"
            width="200"
          />
          <el-table-column
            label="行业类型"
            align="left"
            show-overflow-tooltip
            prop="industryNamePath"
            width="200"
          />
          <el-table-column
            label="岗位类型"
            align="left"
            show-overflow-tooltip
            prop="postName"
            width="200"
          />
          <el-table-column
            label="职业/工种"
            align="left"
            show-overflow-tooltip
            prop="occupationNamePath"
            width="200"
          />
          <el-table-column
            label="培训等级"
            align="left"
            show-overflow-tooltip
            prop="trainLevelName"
            width="100"
          />
          <el-table-column
            label="班级状态"
            align="center"
            show-overflow-tooltip
            prop="projectState"
            width="160px"
            ><template slot-scope="scope">
              <span>{{
                $setDictionary("PROJECTSTATE", scope.row.projectState)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="结业人数"
            align="left"
            show-overflow-tooltip
            prop="settleNum"
            width="100"
          />
          <el-table-column
            label="总课时"
            align="left"
            show-overflow-tooltip
            prop="totalPeriod"
            width="100"
          />
          <el-table-column
            label="公共课时"
            align="left"
            show-overflow-tooltip
            prop="publicLessonNum"
            width="100"
          />
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <div class="Batch">
      <div class="BatchBox">
        <div class="BatchBoxFirst">
          <p>已选班级</p>
          <p>（{{ backArr.length }}）</p>
        </div>
        <div class="BatchBoxSecond ovy-a">
          <div class="BatchforBox">
            <div
              v-for="(item, index) in backArr"
              :key="index"
              class="BatchforFlex"
            >
              <div class="BatchImgBox" @click="deleteImg(item)">
                <img
                  :src="require('@/assets/close_.png')"
                  alt=""
                  @mouseover="transToWhite(index, $event)"
                  @mouseleave="transToBlack(index, $event)"
                />
              </div>
              <span style="padding-left: 5px">{{ item.projectName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "toBeSettledListChildrenFirst",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  props: ["name", "multipleTables", "backArrs"],
  data() {
    return {
      // 页面检索数据
      retrievalDatas: {
        projectCode: "", // 班级编码
        projectName: "", // 班级名称
        compId: "", // 所属机构
        signCompId: "", // 签订机构
        areaId: "", // 行政区划
        params: {}, // 培训类型
        projectState: "", // 班级状态
        startTime: "",
        endTime: "",
      },
      // 所属机构 - 下拉数据
      companyList: [],
      // 签订机构 - 下拉数据
      SignagencyList: [],
      // 行政区划 - 下拉数据
      areatreeList: [],
      // 行政区划 - 整理数据
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 回显 - 培训类型数据
      ruleForm: {
        Trainingtype: "",
      },
      // 班级状态 - 下拉数据
      ClassstatusList: [],
      // 列表勾选的数据
      multipleTable: [],
      // 已选班级的底部数据
      backArr: [],
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {
    this.getareatree();
    this.getclassstatusList();
  },
  updated() {
    setTimeout(() => {
      this.tableData.forEach((item, index) => {
        this.$refs.multipleTable.toggleRowSelection(
          item,
          this.backArr.some((one) => item.projectId == one.projectId)
        );
      });
    }, 200);
  },
  methods: {
    /* 鼠标移入移出 */
    transToWhite(index, e) {
      e.target.src = require("@/assets/error.png");
    },
    transToBlack(index, e) {
      e.target.src = require("@/assets/close_.png");
    },
    deleteImg(itemobj) {
      var newArr = this.backArr.filter(
        (item) => item.projectId != itemobj.projectId
      );
      this.backArr = newArr;
      this.multipleTable.find((el, index) => {
        if (el.projectId == itemobj.projectId) {
          delete this.multipleTable[index];
          this.$refs.multipleTable.toggleRowSelection(el, false);
        }
      });
    },
    // 获取 - 所属机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    // 获取 - 签订机构
    getSignagencyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.SignagencyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.SignagencyList = [];
      }
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    // 培训类型 - 选中
    childBack(params) {
      this.retrievalDatas.params = { ...params };
    },
    // 培训类型 - 清除
    clearParams() {
      this.retrievalDatas.params = {};
    },
    // 获取 - 班级状态
    getclassstatusList() {
      const classstatuslist = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      for (const key in classstatuslist) {
        if (key == '30'||key == "50" || key == "60") {
          list.push({
            value: key,
            label: classstatuslist[key],
          });
        }
      }
      this.ClassstatusList = list;
    },
    // 获取 - 列表数据
    getData(pageNum = 1, stu) {
      // if (stu == "search") {
      //   this.multipleTables = "";
      //   setTimeout(() => {
      //     this.$refs.multipleTable.clearSelection();
      //   }, 200);
      // }
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      // 班级编码
      if (this.retrievalDatas.projectCode) {
        params.projectCode = this.retrievalDatas.projectCode;
      }
      // 班级名称
      if (this.retrievalDatas.projectName) {
        params.projectName = this.retrievalDatas.projectName;
      }
      // 所属机构
      if (this.retrievalDatas.compId) {
        params.compId = this.retrievalDatas.compId;
      }
      if (this.retrievalDatas.signCompId) {
        params.signCompId = this.retrievalDatas.signCompId;
      }
      // 行政区划
      if (this.retrievalDatas.areaId) {
        params.areaId = this.retrievalDatas.areaId;
      }
      // 开班开始时间
      if (this.retrievalDatas.startTime) {
        params.startDateBegin = this.retrievalDatas.startTime[0];
        params.startDateEnd = this.retrievalDatas.startTime[1];
      }
      // 开班结束时间
      if (this.retrievalDatas.endTime) {
        params.endDateBegin = this.retrievalDatas.endTime[0];
        params.endDateEnd = this.retrievalDatas.endTime[1];
      }
      // 培训类型 - 五棵树要传的数据
      if (this.retrievalDatas.params.trainFormId) {
        params.trainTypeId = this.retrievalDatas.params.trainFormId;
      }
      if (this.retrievalDatas.params.levelFormId) {
        params.trainLevelId = this.retrievalDatas.params.levelFormId;
      }
      if (this.retrievalDatas.params.occFormId) {
        params.occupationId = this.retrievalDatas.params.occFormId;
      }
      if (this.retrievalDatas.params.postFormId) {
        params.postId = this.retrievalDatas.params.postFormId;
      }
      if (this.retrievalDatas.params.industryFormId) {
        params.industryId = this.retrievalDatas.params.industryFormId;
      }
      // 班级状态
      if (this.retrievalDatas.projectState) {
        params.projectState = this.retrievalDatas.projectState;
      }
      this.doFetch({
        url: "/biz/new/bill/paymentStatementList",
        params,
        pageNum,
      });
      this.multipleTable = this.backArr;
      // this.backArr = this.backArrs || [];
      // if (this.multipleTables == "multipleTable") {
      //   setTimeout(() => {
      //     this.$refs.multipleTable.clearSelection();
      //   }, 200);
      // }
    },
    // 导出
    getExport() {
      const params = {
      };
      // 班级编码
      if (this.retrievalDatas.projectCode) {
        params.projectCode = this.retrievalDatas.projectCode;
      }
      // 班级名称
      if (this.retrievalDatas.projectName) {
        params.projectName = this.retrievalDatas.projectName;
      }
      // 所属机构
      if (this.retrievalDatas.compId) {
        params.compId = this.retrievalDatas.compId;
      }
      if (this.retrievalDatas.signCompId) {
        params.signCompId = this.retrievalDatas.signCompId;
      }
      // 行政区划
      if (this.retrievalDatas.areaId) {
        params.areaId = this.retrievalDatas.areaId;
      }
      // 开班开始时间
      if (this.retrievalDatas.startTime) {
        params.startDateBegin = this.retrievalDatas.startTime[0];
        params.startDateEnd = this.retrievalDatas.startTime[1];
      }
      // 开班结束时间
      if (this.retrievalDatas.endTime) {
        params.endDateBegin = this.retrievalDatas.endTime[0];
        params.endDateEnd = this.retrievalDatas.endTime[1];
      }
      // 培训类型 - 五棵树要传的数据
      if (this.retrievalDatas.params.trainFormId) {
        params.trainTypeId = this.retrievalDatas.params.trainFormId;
      }
      if (this.retrievalDatas.params.levelFormId) {
        params.trainLevelId = this.retrievalDatas.params.levelFormId;
      }
      if (this.retrievalDatas.params.occFormId) {
        params.occupationId = this.retrievalDatas.params.occFormId;
      }
      if (this.retrievalDatas.params.postFormId) {
        params.postId = this.retrievalDatas.params.postFormId;
      }
      if (this.retrievalDatas.params.industryFormId) {
        params.industryId = this.retrievalDatas.params.industryFormId;
      }
      // 班级状态
      if (this.retrievalDatas.projectState) {
        params.projectState = this.retrievalDatas.projectState;
      }
      this.$post("/biz/new/bill/paymentStatementList/export", params)
        .then((res) => {
          if (res.status == "0") {
            let list = [...res.data];
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 勾选列表数据
    checkListData(rowArr) {
      this.backArr = rowArr;
      this.multipleTable = rowArr;
    },
    // 生成结算单
    generateStatement() {
      if (this.multipleTable.length > 0) {
        let projectIds = [];
        projectIds = this.multipleTable.map((t) => {
          return t.projectId;
        });
        if (new Set(this.multipleTable.map((t) => t.trainTypeId)).size > 1) {
          this.$message({
            message: "不同培训类型(第一棵树)不能生成一个结算单",
            type: "error",
          });
        } else {
          this.$post("/biz/new/bill/generateStatement", {
            projectIds,
          })
            .then((res) => {
              if (res.status == 0) {
                this.$router.push({
                  path: "/web/financialSettlement/settlement/singleAdministrationChildrenFirstEdit",
                  query: {
                    editInfo: JSON.stringify({
                      id: projectIds,
                      type: "first",
                      addEdit: "add",
                      billApplySource:'0'
                    }),
                  },
                });
              }
            })
            .catch(() => {
              return;
            });
        }
      } else {
        this.$message({
          message: "请至少勾选一项!",
          type: "warning",
        });
      }
    },
    // 生成旧结算单
    generateOldStatement() {
      if (this.multipleTable.length > 0) {
        let projectIds = [];
        let trainTypeIds = [];
        projectIds = this.multipleTable.map((t) => {
          return t.projectId;
        });
        trainTypeIds = this.multipleTable.map((t) => {
          return t.trainTypeId;
        });
        //  if (new Set(this.multipleTable.map(t => t.trainTypeId)).size > 1) {
        //     this.$message({
        //       message: "不同培训类型(第一棵树)不能生成一个结算单",
        //       type: "error",
        //     });
        //  } else {
        this.$post("/biz/new/bill/generateOldStatement", {
          projectIds,
        })
          .then((res) => {
            if (res.status == 0) {
              this.$router.push({
                path: "/web/financialSettlement/settlement/singleAdministrationChildrenFirstOldEdit",
                query: {
                  editInfo: JSON.stringify({
                    id: projectIds,
                    type: "first",
                    addEdit: "add",
                    trainTypeIds,
                  }),
                },
              });
            }
          })
          .catch(() => {
            return;
          });
        // }
      } else {
        this.$message({
          message: "请至少勾选一项!",
          type: "warning",
        });
      }
    },
    // 生成作废结算单
    generateVoidStatement() {
      if (this.multipleTable.length > 0) {
        let projectIds = [];
        let trainTypeIds = [];
        projectIds = this.multipleTable.map((t) => {
          return t.projectId;
        });
        trainTypeIds = this.multipleTable.map((t) => {
          return t.trainTypeId;
        });
        //  if (new Set(this.multipleTable.map(t => t.trainTypeId)).size > 1) {
        //     this.$message({
        //       message: "不同培训类型(第一棵树)不能生成一个结算单",
        //       type: "error",
        //     });
        //  } else {
        this.$post("/biz/new/bill/generateInvalidStatement", {
          projectIds,
        })
          .then((res) => {
            if (res.status == 0) {
              this.$router.push({
                path: "/web/financialSettlement/settlement/singleAdministrationChildrenFirstVoidEdit",
                query: {
                  editInfo: JSON.stringify({
                    id: projectIds,
                    type: "first",
                    addEdit: "add",
                    trainTypeIds,
                  }),
                },
              });
            }
          })
          .catch(() => {
            return;
          });
        // }
      } else {
        this.$message({
          message: "请至少勾选一项!",
          type: "warning",
        });
      }
    },
    // 列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 5 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  mounted: function () {},
  watch: {
    name(newVal) {
      if (newVal == "first") {
        this.getData();
        this.$refs.multipleTable.clearSelection();
      }
    },
    $route: {
      handler: function (val) {
        if (val.query.refresh) {
          this.getData();
          this.backArr = [];
          this.$refs.multipleTable.clearSelection();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.Batch {
  padding: 1rem 1rem;
  position: absolute;
  bottom: 0px;
  width: 100%;
  .BatchBox {
    display: flex;
    height: 70px;
    border: 1px solid #ebeef5;
    border-radius: 5px;
    .BatchBoxFirst {
      width: 260px;
      background: #f2f7fd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .BatchBoxSecond {
      width: 100%;
      .BatchforBox {
        display: flex;
        flex-wrap: wrap;
        .BatchforFlex {
          display: flex;
          align-items: center;
          padding: 5px 20px;
          cursor: pointer;
          .BatchImgBox {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
</style>
