<template>
  <!-- 重构 - 结算管理 - 待结算列表 - 代理结算单 -->
  <div>
    <div class="operationControl searchboxfl">
      <div class="searchbox" style="margin-top: 10px">
        <div title="代理商" class="searchboxItem ci-full">
          <span class="itemLabel">代理商:</span>
          <el-select
            size="small"
            v-model="agencySxDatas.agencyId"
            remote
            :remote-method="getCompanyList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in companyList"
              :key="item.agencyId"
              :label="item.agencyName"
              :value="item.agencyId"
            ></el-option>
          </el-select>
        </div>
        <div title="合格日期" class="searchboxItem ci-full">
          <span class="itemLabel">合格日期:</span>
          <el-date-picker
            clearable
            size="small"
            v-model="agencySxDatas.graduationTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
        <div title="学员类型" class="searchboxItem ci-full">
          <span class="itemLabel">学员类型:</span>
          <el-select
            v-model="agencySxDatas.userType"
            placeholder="请选择学员类型"
            clearable
            size="small"
          >
            <el-option
              v-for="item in archiveTypeData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div title="代理类型" class="searchboxItem ci-full">
          <span class="itemLabel">代理类型:</span>
          <el-select
            v-model="agencySxDatas.agencyType"
            placeholder="请选择代理类型"
            clearable
            size="small"
          >
            <el-option
              v-for="item in agencyTypeData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="df" style="padding-left: 10px">
          <el-button
            type="primary"
            class="bgc-bv"
            round
            @click="getData((pageNum = 1), 'search')"
            >查询</el-button
          >
          <el-button
            type="primary"
            class="bgc-bv"
            round
            @click="generateStatement"
            >生成结算单</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          row-key="agencyId"
          @selection-change="checkListData"
          :header-cell-style="tableHeader"
          stripe
          ><el-table-column
            type="selection"
            width="50px"
            align="center"
            fixed
            :selectable="selectable"
          ></el-table-column>
          <el-table-column
            label="序号"
            align="left"
            type="index"
            :index="indexMethod"
            fixed
            width="60px"
          />
          <el-table-column
            label="代理商"
            align="left"
            show-overflow-tooltip
            prop="companyName"
          />
          <el-table-column
            label="代理类型"
            align="left"
            show-overflow-tooltip
            prop="agencyType"
          >
          <template slot-scope="scope">
            {{$setDictionary('AGENCY_TYPE',scope.row.agencyType)}}
          </template>
          </el-table-column>
          <el-table-column
            label="联系人"
            align="left"
            show-overflow-tooltip
            prop="agencyName"
          />
          <el-table-column
            label="待结算人数"
            align="left"
            show-overflow-tooltip
            prop="toSettleNum"
          />
          <el-table-column
            label="已结算人数"
            align="left"
            show-overflow-tooltip
            prop="settleNum"
          />
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "toBeSettledListChildrenThree",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name", "multipleTable", "backArrs"],
  data() {
    return {
      // 页面检索数据
      agencySxDatas: {
        agencyId: "", // 所属机构
        graduationTime: "", // 结业日期
        userType: "", // 学员类型
        agencyType:'', //代理类型
      },
      // 课程提供方 - 下拉数据
      companyList: [],
      // 列表勾选的数据
      multipleSelection: [],
      archiveTypeData: [
      ],
      agencyTypeData:[],
    };
  },
  computed: {},
  created() {
    this.getArchiveTypeDataList()
  },
  updated() {
  
  },
  methods: {
   
       getArchiveTypeDataList() {
      const archiveTypeData = this.$setDictionary("USERTYPE_SX", "list");
      const agencyTypeData = this.$setDictionary("AGENCY_TYPE", "list");
      for (const key in archiveTypeData) {
        this.archiveTypeData.push({
          value: key,
          label: archiveTypeData[key],
        });
      }
      for (const key in agencyTypeData) {
        this.agencyTypeData.push({
          value: key,
          label: agencyTypeData[key],
        });
      }
    },
    // 获取 - 所属机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/biz/mercantile/agency/searchList", { agencyName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1, stu) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      // 代理商
      if (this.agencySxDatas.agencyId) {
        params.agencyId = this.agencySxDatas.agencyId;
      }
      // 合格日期
      if (this.agencySxDatas.graduationTime) {
        params.qualifyDateStart = this.agencySxDatas.graduationTime[0];
        params.qualifyDateEnd = this.agencySxDatas.graduationTime[1];
      }
      // 学员类型
      if (this.agencySxDatas.userType) {
        params.userType = this.agencySxDatas.userType;
      }
      // 代理类型
      if (this.agencySxDatas.agencyType) {
        params.agencyType = this.agencySxDatas.agencyType;
      }
      this.doFetch({
        url: "/biz/new/agentBill/pawPageList",
        params,
        pageNum,
      });
    },
    // 勾选列表数据
    checkListData(val) {
       if(val.length > 1){
        this.$refs.multipleTable.clearSelection()
        this.$refs.multipleTable.toggleRowSelection(val.pop())
      }else{
        this.multipleSelection = val;
      }  
    },
    // 生成结算单
    generateStatement() {
      if (this.multipleSelection.length > 0) {
        let agencyId = '';
        let toSettleNum = 0;
        let userList = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          console.log(this.multipleSelection);
          agencyId = this.multipleSelection[0].agencyId;
          toSettleNum = this.multipleSelection[0].toSettleNum;
          userList = this.multipleSelection[i].userList
        }
        
        this.$post("/biz/new/agentBill/generatePawBill", {
          agencyId:agencyId,
        })
          .then((res) => {
            if (res.status == 0) {
              this.$router.push({
                path: "/web/financialSettlement/settlement/singleAdministrationChildrenFourEdit",
                query: {
                  editInfo: JSON.stringify({
                    id: agencyId,
                    toSettleNum,
                    type: "four",
                    addEdit: "add",
                    userList
                  }),
                },
              });
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.$message({
          message: "请至少勾选一项!",
          type: "warning",
        });
      }
    },
    // 列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  mounted: function () {},
  watch: {
    name(newVal) {
      if (newVal == "four") {
        this.getData();
        this.$refs.multipleTable.clearSelection();
      }
    },
    $route: {
      handler: function (val) {
        if (val.query.refresh) {
          this.getData();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
</style>
<style lang="less">
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
.Batch {
  padding: 1rem 1rem;
  position: absolute;
  bottom: 0px;
  width: 100%;
  .BatchBox {
    display: flex;
    height: 70px;
    border: 1px solid #ebeef5;
    border-radius: 5px;
    .BatchBoxFirst {
      width: 260px;
      background: #f2f7fd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .BatchBoxSecond {
      width: 100%;
      .BatchforBox {
        display: flex;
        flex-wrap: wrap;
        .BatchforFlex {
          display: flex;
          align-items: center;
          padding: 5px 20px;
          cursor: pointer;
          .BatchImgBox {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
</style>
