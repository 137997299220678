import { render, staticRenderFns } from "./toBeSettledListChildrenFirst.vue?vue&type=template&id=2d6c53d7"
import script from "./toBeSettledListChildrenFirst.vue?vue&type=script&lang=js"
export * from "./toBeSettledListChildrenFirst.vue?vue&type=script&lang=js"
import style0 from "./toBeSettledListChildrenFirst.vue?vue&type=style&index=0&id=2d6c53d7&prod&lang=less&scope=true"
import style1 from "./toBeSettledListChildrenFirst.vue?vue&type=style&index=1&id=2d6c53d7&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports