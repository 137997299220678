<template>
  <!-- 重构 - 结算管理 - 待结算列表 - 付款结算单 -->
  <div>
    <div class="operationControl searchboxfl">
      <div class="searchbox">
        <div title="课程名称" class="searchboxItem ci-full">
          <span class="itemLabel">课程名称:</span>
          <el-input v-model="retrievalDatas.courseName" type="text" size="small" placeholder="请输入课程名称" clearable />
        </div>
        <div title="课程提供方" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width:7rem">课程提供方:</span>
          <el-select size="small" v-model="retrievalDatas.compOwnerId" remote :remote-method="getCompanyList" filterable
            clearable placeholder="请至少输入两个字搜索">
            <el-option v-for="item in companyList" :key="item.compId" :label="item.compName"
              :value="item.compId"></el-option>
          </el-select>
        </div>
        <div title="行政区划" class="searchboxItem ci-full">
          <span class="itemLabel">行政区划:</span>
          <el-cascader clearable filterable v-model="retrievalDatas.areaId" :options="areatreeList" :props="propsarea"
            size="small"></el-cascader>
        </div>
        <div title="班级编码" class="searchboxItem ci-full">
          <span class="itemLabel">班级编码:</span>
          <el-input v-model="retrievalDatas.projectCode" type="text" size="small" placeholder="请输入班级编码" clearable />
        </div>
      </div>
      <div class="searchbox" style="margin-top: 10px">
        <div title="使用班级" class="searchboxItem ci-full">
          <span class="itemLabel">使用班级:</span>
          <el-input v-model="retrievalDatas.projectName" type="text" size="small" placeholder="请输入使用班级" clearable />
        </div>
        <div title="培训类型" class="searchboxItem ci-full">
          <span class="itemLabel">培训类型:</span>
          <tree ref="tree" @eventBtn="childBack" :ruleForm="ruleForm" size="small" @clearParams="clearParams" typeStu />
        </div>
        <div title="班级状态" class="searchboxItem">
          <span class="itemLabel">班级状态:</span>
          <el-select size="small" v-model="retrievalDatas.projectState" placeholder="请选择班级状态" clearable>
            <el-option v-for="item in ClassstatusList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <div title="收款到账状态" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width:7rem">收款到账状态:</span>
          <el-select size="small" v-model="retrievalDatas.accountState" placeholder="请选择收款到账状态" clearable><el-option
              v-for="item in invoiceOrNot" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
      </div>
      <div class="searchbox" style="margin-top: 10px">
        <div title="班级所属机构" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width: 7rem">班级所属机构:</span>
          <el-select size="small" v-model="retrievalDatas.signCompId" remote :remote-method="getSignagencyList"
            filterable clearable placeholder="请至少输入两个字搜索">
            <el-option v-for="item in SignagencyList" :key="item.compId" :label="item.compName"
              :value="item.compId"></el-option>
          </el-select>
        </div>
        <div title="是否纳入核算" class="searchboxItem">
          <span class="itemLabel" style="min-width: 7rem">是否纳入核算:</span>
          <el-select size="small" v-model="retrievalDatas.isAccounting" placeholder="请选择是否纳入核算" clearable>
            <el-option :key="true" label="纳入" :value="true"></el-option>
            <el-option :key="false" label="不纳入" :value="false"></el-option>
          </el-select>
        </div>
        <div title="开班开始时间" class="searchboxItem">
          <span class="itemLabel" style="min-width: 7rem">开班开始时间:</span>
          <el-date-picker clearable size="small" v-model="retrievalDatas.openClassStartTime" type="daterange"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            value-format="yyyy-MM-dd"></el-date-picker>
        </div>
        <div title="收款结算单" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width: 6rem">收款结算单:</span>
          <el-input v-model="retrievalDatas.billNo" type="text" size="small" placeholder="请输入收款结算单" clearable />
        </div>
      </div>
      <div class="searchbox" style="margin-top: 10px">
        <div title="开班开始时间" class="searchboxItem">
          <span class="itemLabel" style="min-width: 7rem">开班结束时间:</span>
          <el-date-picker clearable size="small" v-model="retrievalDatas.openClassEndTime" type="daterange"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            value-format="yyyy-MM-dd"></el-date-picker>
        </div>
        <div title="开班开始时间" class="searchboxItem">
          <span class="itemLabel" style="min-width: 7rem">到账时间:</span>
          <el-date-picker clearable size="small" v-model="retrievalDatas.receiptTime" type="daterange"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            value-format="yyyy-MM-dd"></el-date-picker>
        </div>
        <div class="df" style="padding-left:10px">
          <el-button type="primary" class="bgc-bv" round @click="getData((pageNum = 1), 'search')">查询</el-button>
          <el-button type="primary" class="bgc-bv" round @click="generateStatement">生成结算单</el-button>
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
          style="width: 100%" row-key="rowNum" @selection-change="checkListData" :header-cell-style="tableHeader"
          stripe><el-table-column type="selection" width="50px" align="center" fixed
            :reserve-selection="true"></el-table-column>
          <el-table-column label="序号" align="left" type="index" :index="indexMethod" fixed width="60px" />
          <el-table-column label="课程名称" align="left" show-overflow-tooltip prop="courseName" width="200" />
          <el-table-column label="课程提供方" align="left" show-overflow-tooltip prop="compOwnerName" width="300" />
          <el-table-column label="课程提供方总课时" align="left" show-overflow-tooltip prop="kpointOwnerTotalLessonNum"
            width="120" />
          <el-table-column label="课程提供方公共课时" align="left" show-overflow-tooltip prop="kpointOwnerPublicLessonNum"
            width="130" />
          <el-table-column label="收款到账状态" align="left" show-overflow-tooltip width="150">
            <template slot-scope="scope">
              {{ invoiceOrNot.find(e => e.value == scope.row.accountState) ? invoiceOrNot.find(e => e.value ==
            scope.row.accountState).label : '' }}
            </template>
          </el-table-column>
          <el-table-column label="到账日期" align="left" prop="payeeDate" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.payeeDate | momentDate }}
            </template>
          </el-table-column>
          <el-table-column label="是否纳入核算" align="center" show-overflow-tooltip width="110">
            <template slot-scope="scope">
              {{ scope.row.isAccounting === true ? '纳入' : scope.row.isAccounting === false ? '不纳入' : '--' }}
              <!-- <el-switch v-model="scope.row.accountState" active-color="#13ce66" inactive-color="#ff4949"
                active-text="纳入核算" inactive-text="不纳入核算" active-value="100" inactive-value="0">
              </el-switch> -->
            </template>
          </el-table-column>

          <el-table-column label="收款结算单号" align="left" prop="billNo" show-overflow-tooltip width="200" />
          <el-table-column label="收款结算日期" align="left" prop="createTime" show-overflow-tooltip width="200" />
          <el-table-column label="班级编码" align="left" prop="projectCode" show-overflow-tooltip width="200" />
          <el-table-column label="使用班级" align="left" prop="projectName" show-overflow-tooltip width="200" />
          <el-table-column label="班级开始时间" align="left" prop="startDate" width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.startDate | momentDate }}
            </template>
          </el-table-column>
          <el-table-column label="班级结束时间" align="left" prop="endDate" width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.endDate | momentDate }}
            </template>
          </el-table-column>
          <el-table-column label="班级所属机构" align="left" prop="compName" show-overflow-tooltip width="200" />
          <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaName" width="200" />
          <el-table-column label="培训类型" align="left" show-overflow-tooltip prop="trainTypeName" width="200" />
          <el-table-column label="行业类型" align="left" show-overflow-tooltip prop="industryName" width="200" />
          <el-table-column label="岗位类型" align="left" show-overflow-tooltip prop="postName" width="200" />
          <el-table-column label="职业/工种" align="left" show-overflow-tooltip prop="occupationName" width="200" />
          <el-table-column label="培训等级" align="left" show-overflow-tooltip prop="trainLevelName" width="100" />
          <el-table-column label="班级状态" align="left" show-overflow-tooltip prop="auditState" width="100">
            <template slot-scope="scope">
              {{ $setDictionary("PROJECTSTATE", scope.row.projectState) }}
            </template>
          </el-table-column>
          <el-table-column label="结业人数" align="left" show-overflow-tooltip prop="settleNum" width="100" />
          <el-table-column label="班级总课时" align="left" show-overflow-tooltip prop="totalPeriod" width="100" />
          <el-table-column label="班级公共课时" align="left" show-overflow-tooltip prop="publicLessonNum" width="120" />
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <div class="Batch">
      <div class="BatchBox">
        <div class="BatchBoxFirst">
          <p>已选班级</p>
          <p>（{{ backArr.length }}）</p>
        </div>
        <div class="BatchBoxSecond ovy-a">
          <div class="BatchforBox">
            <div v-for="(item, index) in backArr" :key="index" class="BatchforFlex">
              <div class="BatchImgBox" @click="deleteImg(item)">
                <img :src="require('@/assets/close_.png')" alt="" @mouseover="transToWhite(index, $event)"
                  @mouseleave="transToBlack(index, $event)" />
              </div>
              <span style="padding-left:5px">{{ item.projectName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
export default {
  name: "toBeSettledListChildrenSecond",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  props: ["name", "multipleTables", "backArrs"],
  data() {
    return {
      // 页面检索数据
      retrievalDatas: {
        courseName: "", // 课程名称
        compOwnerId: "", // 课程提供方
        accountState: '30', // 收款是否到账
        projectName: "", // 使用班级
        projectCode: "", // 班级编码
        billNo: "", // 收款结算单
        areaId: "", // 行政区划
        params: {}, // 培训类型
        projectState: "", // 班级状态
        isAccounting: "", // 是否纳入核算
        openClassStartTime: [], // 开班开始时间
        openClassEndTime: [], // 开班结束时间
        receiptTime: [], // 到账时间
      },
      // 课程提供方 - 下拉数据
      companyList: [],
      // 收款是否到账 - 下拉数据
      invoiceOrNot: [
        {
          value: "10",
          label: "未到账",
        },
        {
          value: "20",
          label: "部分到账",
        },
        {
          value: "30",
          label: "全部到账",
        },
      ],
      // 行政区划 - 下拉数据
      areatreeList: [],
      // 行政区划 - 整理数据
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 回显 - 培训类型数据
      ruleForm: {
        Trainingtype: "",
      },
      // 班级状态 - 下拉数据
      ClassstatusList: [],
      // 列表勾选的数据
      multipleTable: [],
      // 已选班级的底部数据
      backArr: [],
      // 签订机构 - 下拉数据
      SignagencyList: [],
      getbackarr: false,
    };
  },
  computed: {},
  created() {
    this.getareatree();
    this.getclassstatusList();

  },
  updated() {
    setTimeout(() => {
      this.tableData.forEach((item, index) => {
        this.$refs.multipleTable.toggleRowSelection(item, this.backArr.some(one => (item.rowNum == one.rowNum)))
      })
    }, 200)

  },
  methods: {
    /* 鼠标移入移出 */
    transToWhite(index, e) {
      e.target.src = require("@/assets/error.png");
    },
    transToBlack(index, e) {
      e.target.src = require("@/assets/close_.png");
    },
    deleteImg(itemobj) {
      var newArr = this.backArr.filter((item) => item.rowNum != itemobj.rowNum);
      this.backArr = newArr;
      this.multipleTable.find((el, index) => {
        if (el.rowNum == itemobj.rowNum) {
          delete this.multipleTable[index];
          this.$refs.multipleTable.toggleRowSelection(el, false);
        }
      });
    },
    // 获取 - 课程提供方
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    // 培训类型 - 选中
    childBack(params) {
      this.retrievalDatas.params = { ...params };
    },
    // 培训类型 - 清除
    clearParams() {
      this.retrievalDatas.params = {};
    },
    // 获取 - 班级状态
    getclassstatusList() {
      const classstatuslist = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      for (const key in classstatuslist) {
        if (key == "50" || key == "60") {
          list.push({
            value: key,
            label: classstatuslist[key],
          });
        }
      }
      this.ClassstatusList = list;
    },
    // 获取 - 列表数据
    getData(pageNum = 1, stu) {
      //   this.getbackarr = JSON.parse(sessionStorage.getItem("backarr"));
      //   if (this.getbackarr) {
      //     this.backArr = [];
      //     this.multipleTable = [];
      //     setTimeout(() => {
      //       this.$refs.multipleTable.clearSelection();
      //     }, 200);
      //  }
      if (stu == "search") {
        this.getbackarr = false
      }
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        accountState: this.retrievalDatas.accountState,
      };
      // 课程名称
      if (this.retrievalDatas.courseName) {
        params.courseName = this.retrievalDatas.courseName;
      }
      // 课程提供方
      if (this.retrievalDatas.compOwnerId) {
        params.compOwnerId = this.retrievalDatas.compOwnerId;
      }

      // 使用班级
      if (this.retrievalDatas.projectName) {
        params.projectName = this.retrievalDatas.projectName;
      }
      // 班级编码
      if (this.retrievalDatas.projectCode) {
        params.projectCode = this.retrievalDatas.projectCode;
      }
      // 收款结算单
      if (this.retrievalDatas.billNo) {
        params.billNo = this.retrievalDatas.billNo;
      }
      // 行政区划
      if (this.retrievalDatas.areaId) {
        params.areaId = this.retrievalDatas.areaId;
      }
      // 培训类型 - 五棵树要传的数据
      if (this.retrievalDatas.params.trainFormId) {
        params.trainTypeId = this.retrievalDatas.params.trainFormId;
      }
      if (this.retrievalDatas.params.levelFormId) {
        params.trainLevelId = this.retrievalDatas.params.levelFormId;
      }
      if (this.retrievalDatas.params.occFormId) {
        params.occupationId = this.retrievalDatas.params.occFormId;
      }
      if (this.retrievalDatas.params.postFormId) {
        params.postId = this.retrievalDatas.params.postFormId;
      }
      if (this.retrievalDatas.params.industryFormId) {
        params.industryId = this.retrievalDatas.params.industryFormId;
      }
      // 班级状态
      if (this.retrievalDatas.projectState) {
        params.projectState = this.retrievalDatas.projectState;
      }
      // 班级所属机构
      if (this.retrievalDatas.signCompId) {
        params.compId = this.retrievalDatas.signCompId;
      }
      if (this.retrievalDatas.isAccounting === true || this.retrievalDatas.isAccounting === false) {
        params.isAccounting = this.retrievalDatas.isAccounting;
      }
      // 开班开始时间
      if (this.retrievalDatas.openClassStartTime) {
        params.startDateStart = this.retrievalDatas.openClassStartTime[0];
        params.startDateEnd = this.retrievalDatas.openClassStartTime[1];
      }
      // 开班结束时间
      if (this.retrievalDatas.openClassEndTime) {
        params.endDateStart = this.retrievalDatas.openClassEndTime[0];
        params.endDateEnd = this.retrievalDatas.openClassEndTime[1];
      }
      // 到账时间
      if (this.retrievalDatas.receiptTime) {
        params.payeeDateStart = this.retrievalDatas.receiptTime[0];
        params.payeeDateEnd = this.retrievalDatas.receiptTime[1];
      }
      this.doFetch({
        url: "/biz/new/bill/pay/pageList",
        params,
        pageNum,
      });
      this.multipleSelection = this.backArr;
      // this.backArr = this.backArrs || []
      // if (this.multipleTables == "multipleTable") {
      //   setTimeout(() => {
      //     this.$refs.multipleTable.clearSelection();
      //   }, 200);
      // }
    },
    // 勾选列表数据
    checkListData(val) {
      this.backArr = val;
      this.multipleTable = val;
    },
    // 生成结算单
    generateStatement() {
      if (this.multipleTable.length > 0) {
        let projectIds = [];
        for (let i = 0; i < this.multipleTable.length; i++) {
          projectIds.push({
            compOwnerId: this.multipleTable[i].compOwnerId, // 课程提供方名称Id
            projectCourseId: this.multipleTable[i].projectCourseId, // 班级课程id
            projectId: this.multipleTable[i].projectId, // 班级id
            trainTypeId: this.multipleTable[i].trainTypeId, // 培训类型id
            billNo: this.multipleTable[i].billNo,
            billId: this.multipleTable[i].billId,
          });
        }
        if (new Set(this.multipleTable.map((t) => t.trainTypeId)).size > 1) {
          this.$message({
            message: "不同培训类型(第一棵树)不能生成一个结算单",
            type: "error",
          });
          return;
        }
        if (new Set(this.multipleTable.map((t) => t.compOwnerId)).size > 1) {
          this.$message({
            message: "课程提供方不同,无法生成一个结算单",
            type: "error",
          });
          return;
        }
        this.$router.push({
          path:
            "/web/financialSettlement/settlement/singleAdministrationChildrenSecondEdit",
          query: {
            editInfo: JSON.stringify({
              id: projectIds,
              type: "second",
              addEdit: "add",
            }),
          },
        });
      } else {
        this.$message({
          message: "请至少勾选一项!",
          type: "warning",
        });
      }
    },
    // 列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 5 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 82 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    // 获取 - 签订机构
    getSignagencyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.SignagencyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.SignagencyList = [];
      }
    },
  },
  mounted: function () { },
  watch: {
    name(newVal) {
      if (newVal == "second") {
        this.getData();
        this.$refs.multipleTable.clearSelection();
      }
    },
    $route: {
      handler: function (val) {
        if (val.query.refresh) {
          this.getData();
          this.backArr = [];
          this.$refs.multipleTable.clearSelection();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}

/deep/.el-switch__label--left.is-active+.el-switch__core {
  border-color: rgb(255, 73, 73) !important;
  background-color: rgb(255, 73, 73) !important;
}

/deep/.el-switch.is-checked .el-switch__core {
  background-color: #5fb878 !important;
  border-color: #5fb878 !important;
}

/deep/ .el-switch__label--left {
  left: 0 !important;
  z-index: 999 !important;
}

/deep/ .el-switch__label--right {
  right: 0 !important;
  z-index: 999 !important;
}

/deep/ .el-switch__label {
  color: #666 !important;
}

/deep/ .el-switch__label.is-active {
  color: #409eff !important;
}

.el-textarea {
  resize: none;

  .el-textarea__inner {
    height: 200px;
  }
}

.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;

    &:before {
      content: "\e6df" !important;
    }
  }

  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;

    .el-tabs__content {
      flex: 1;

      .el-tab-pane {
        height: 100%;
      }
    }
  }
}

.Batch {
  padding: 1rem 1rem;
  position: absolute;
  bottom: 0px;
  width: 100%;

  .BatchBox {
    display: flex;
    height: 70px;
    border: 1px solid #ebeef5;
    border-radius: 5px;

    .BatchBoxFirst {
      width: 260px;
      background: #f2f7fd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .BatchBoxSecond {
      width: 100%;

      .BatchforBox {
        display: flex;
        flex-wrap: wrap;

        .BatchforFlex {
          display: flex;
          align-items: center;
          padding: 5px 20px;
          cursor: pointer;

          .BatchImgBox {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
</style>