<template>
  <!-- 重构 - 结算管理 - 待结算列表 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">待结算列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName" @tab-click="tabsChange"
          ><el-tab-pane label="收款结算单" name="first">
            <toBeSettledListChildrenFirst :name="activeName" ref="first"   :multipleTable="multipleTable" :backArrs="backArrs"/>
          </el-tab-pane>
          <el-tab-pane label="付款结算单" name="second">
            <toBeSettledListChildrenSecond :name="activeName" ref="second" :multipleTable="multipleTable" :backArrs="backArrs"/>
          </el-tab-pane>
          <el-tab-pane label="代理结算单" name="three">
            <toBeSettledListChildrenThree :name="activeName" ref="three" :multipleTable="multipleTable" :backArrs="backArrs" />
          </el-tab-pane>
          <el-tab-pane label="代理结算单(普惠)" name="four">
            <toBeSettledListChildrenFour :name="activeName" ref="three" :multipleTable="multipleTable" :backArrs="backArrs" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import toBeSettledListChildrenFirst from "@/views/financialSettlement/settlement/toBeSettledListChildrenFirst";
import toBeSettledListChildrenSecond from "@/views/financialSettlement/settlement/toBeSettledListChildrenSecond";
import toBeSettledListChildrenThree from "@/views/financialSettlement/settlement/toBeSettledListChildrenThree";
import toBeSettledListChildrenFour from "@/views/financialSettlement/settlement/toBeSettledListChildrenFour";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "toBeSettledList",
  components: {
    toBeSettledListChildrenFirst,
    toBeSettledListChildrenSecond,
    toBeSettledListChildrenThree,
    toBeSettledListChildrenFour
  },
  mixins: [List],
  data() {
    return {
      activeName: "",
      multipleTable:''
    };
  },
  created() {
    this.activeName = this.$route.query.active || "first";
    
  },
  mounted() {},
  methods: {
    tabsChange(e) {
      this.activeName = e.name;
    },
  },
  watch: {
    $route: function (route) {
      if (route.query.refresh) {
        this.$refs[route.query.active].getData(-1,'search');
        this.multipleTable = this.$route.query.multipleTable;
        this.backArrs = []
        // console.log(this.$route.query.multipleTable);
        // this.multipleTable = this.$route.query.multipleTable || "multipleTable";
      }
    },
  },
};
</script>
<style lang="less">
.el-tab-pane {
  >div {
    height: 100%;
    position: relative;
  }
}
</style>
